export const InstagramIcon = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.933 1.473c2.134 0 2.4 0 3.2.067.8.067 1.2.134 1.467.268.4.133.667.334.933.602.267.268.467.536.6.937.134.268.267.737.267 1.473.067.87.067 1.071.067 3.213 0 2.143 0 2.41-.067 3.214-.067.803-.133 1.205-.267 1.473-.133.401-.333.67-.6.937-.266.268-.533.469-.933.602-.267.134-.733.268-1.467.268-.866.067-1.066.067-3.2.067-2.133 0-2.4 0-3.2-.067-.8-.067-1.2-.134-1.466-.268-.4-.133-.667-.334-.934-.602-.266-.268-.466-.536-.6-.937-.133-.268-.266-.737-.266-1.473-.067-.87-.067-1.071-.067-3.214 0-2.142 0-2.41.067-3.213.066-.803.133-1.205.266-1.473.134-.401.334-.67.6-.937.267-.268.534-.469.934-.602.266-.134.733-.268 1.466-.268.867-.067 1.067-.067 3.2-.067zm0-1.473C5.8 0 5.533 0 4.667.067c-.867 0-1.4.134-1.934.335-.533.2-.933.468-1.4.937C.867 1.808.6 2.209.4 2.745.2 3.28.067 3.815.067 4.686 0 5.556 0 5.824 0 7.966c0 2.143 0 2.41.067 3.28.066.871.2 1.407.4 1.942.2.536.466 1.005.933 1.406.467.469.867.737 1.4.937.533.201 1.067.335 1.933.402C5.6 16 5.867 16 8 16s2.4 0 3.267-.067c.866-.067 1.4-.2 1.933-.402.533-.2 1-.468 1.4-.937.467-.468.733-.87.933-1.406.2-.535.334-1.07.4-1.941.067-.87.067-1.138.067-3.28 0-2.143 0-2.41-.067-3.28-.066-.871-.2-1.407-.4-1.942-.2-.536-.466-1.004-.933-1.406-.467-.469-.867-.736-1.4-.937C12.667.2 12.133.067 11.267 0H7.933z"
      fill="currentColor"
    />
    <path
      d="M7.933 3.883c-2.266 0-4.066 1.807-4.066 4.083 0 2.277 1.8 4.084 4.066 4.084C10.2 12.05 12 10.243 12 7.966c0-2.276-1.8-4.083-4.067-4.083zm0 6.761a2.68 2.68 0 01-2.666-2.678A2.68 2.68 0 017.933 5.29 2.68 2.68 0 0110.6 7.967a2.68 2.68 0 01-2.667 2.677zM12.2 4.686c.515 0 .933-.42.933-.937a.935.935 0 00-.933-.937.935.935 0 00-.933.937c0 .517.418.937.933.937z"
      fill="currentColor"
    />
  </svg>
)

export default InstagramIcon
