export const FacebookIcon = () => (
  <svg width="8" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.08 7.985H4.89V16H1.577V7.985H0V5.168h1.577V3.345C1.577 2.04 2.196 0 4.92 0l2.455.01v2.735H5.595c-.292 0-.703.146-.703.767V5.17h2.477l-.29 2.815z"
      fill="currentColor"
    />
  </svg>
)

export default FacebookIcon
