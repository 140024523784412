import tw, { styled } from "twin.macro"

import useMuwayiTheme from "lib/useMuwayiTheme"
import { transitions } from "styles/Variables"
import FacebookIcon from "icons/Facebook"
import InstagramIcon from "icons/Instagram"

import SRText from "components/SRText"
import icons from "icons"

// styled components
const SocialLink = styled.a(() => [
  tw`
    text-white bg-grey rounded-full 
    grid place-items-center
    mx-2.5 w-8 h-8
    
    hocus:bg-med-grey
  `,
  transitions.link,
])

export const Socials = () => {
  const { SocialMenu } = useMuwayiTheme()
  return (
    <div tw="flex justify-center my-7.5 -mx-2.5">
      {SocialMenu &&
        SocialMenu.map(({ icon, link, id }) => {
          const Icon = icons[icon] || icons.facebook
          return (
            <SocialLink key={id} href={link}>
              <Icon />
              <SRText>{icon}</SRText>
            </SocialLink>
          )
        })}
      {!SocialMenu && (
        <>
          <SocialLink href="https://www.facebook.com/Bunuba-Country-105342501699399">
            <FacebookIcon />
            <SRText>Facebook</SRText>
          </SocialLink>

          <SocialLink href="https://www.instagram.com/bunuba_country/">
            <InstagramIcon />
            <SRText>Instagram</SRText>
          </SocialLink>
        </>
      )}
    </div>
  )
}

export default Socials
