import Image from "components/Image"
import tw from "twin.macro"

import useMuwayiTheme from "lib/useMuwayiTheme"

import Container from "components/Container"
import { Socials } from "components/Socials"
import { H5, BodyDetail } from "styles/Text"

// styled components
const FooterLogos = tw.div`
  -mx-2.5
  flex justify-center items-center flex-wrap 

  md:justify-start
`

export const Footer = () => {
  const { FooterLogoMenu, FooterMenu } = useMuwayiTheme()
  return (
    <>
      <footer tw="mt-auto p-7.5 md:pt-15 print:hidden">
        <Container>
          <FooterLogos>
            {FooterLogoMenu?.map(({ image, link, id }) => (
              <div tw="m-2.5" key={id}>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={link} target="_blank" rel="noopener">
                  <Image
                    src={image.url}
                    alt={image.alternativeText}
                    width={image.width * (55 / image.height)}
                    height="55"
                    objectFit="contain"
                    objectPosition="left"
                  />
                </a>
              </div>
            ))}
            {/* <div tw="m-2.5">
              <img
                src="https://bunuba-media-prod.s3-ap-southeast-2.amazonaws.com/logos/nulungu.svg"
                width="105"
                height="50"
                alt="Nulungu Research Institute"
              />
            </div> */}
            <ul tw="flex-grow text-center md:text-right text-primary font-bold list-none">
              {FooterMenu?.map(({ label, link, id }) => (
                <li key={id} className="m-2.5">
                  <a href={link}>{label}</a>
                </li>
              ))}
            </ul>
          </FooterLogos>

          <div tw="md:flex md:flex-row-reverse md:justify-between md:items-center">
            <Socials />

            <BodyDetail tw="text-med-grey text-center md:text-left">
              &copy; 2021 Visit Bunuba Country. All rights reserved.
            </BodyDetail>
          </div>
        </Container>
      </footer>
      <footer tw="hidden print:block text-right fixed bottom-0 right-0">
        <H5 as="h2" tw="text-primary">
          bunuba.com
        </H5>
      </footer>
    </>
  )
}

export default Footer
